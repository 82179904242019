import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [dinfL1, setDinfL1] = useState('');
  const [townName, setTownName] = useState('');

  const fetchData = async () => {
    try {
      const response = await axios.post('https://us-central1-httpstheawareappcom.cloudfunctions.net/getAgencias', {
        dinfL1: dinfL1,
        townName: townName
      });
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      fetchData();
    }
  };

  const handleButtonClick = () => {
    fetchData();
  };

  const trimKeys = (item) => {
    const trimmedItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        trimmedItem[key.trim()] = item[key];
      }
    }
    return trimmedItem;
  };

  const getCurrentTimeString = () => {
    const date = new Date();
    return date.toTimeString().slice(0, 5);
  };

  const checkTimeAndRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryTime = urlParams.get('t');
  
    // Get current time in Puerto Rico time zone
    const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Puerto_Rico" }));
    const currentTime = currentDate.toTimeString().slice(0, 5);
  
    if (queryTime) {
      const [queryHours, queryMinutes] = queryTime.split(':').map(Number);
      const queryDate = new Date();
      queryDate.setHours(queryHours, queryMinutes, 0);
  
      const timeDifference = (currentDate - queryDate) / (1000 * 60);
  
      if (timeDifference > 5) {
        window.location.href = '/';
      }
    } else {
      // If no query parameter is present, redirect immediately
      window.location.href = '/';
    }
  };
  
  useEffect(() => {
    checkTimeAndRedirect();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Nombre Agencia"
          variant="outlined"
          fullWidth
          value={dinfL1}
          onChange={(e) => setDinfL1(e.target.value)}
          onKeyPress={handleSearch}
          style={{ marginRight: '8px' }}
        />
        <TextField
          label="Pueblo"
          variant="outlined"
          fullWidth
          value={townName}
          onChange={(e) => setTownName(e.target.value)}
          onKeyPress={handleSearch}
          style={{ marginRight: '8px' }}
        />
        <IconButton
          color="primary"
          onClick={handleButtonClick}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Telefono 1</TableCell>
              <TableCell>Telefono 2</TableCell>
              <TableCell>Pueblo</TableCell>
              <TableCell>Dirección</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const trimmedItem = trimKeys(item);
              return (
                <TableRow key={index}>
                  <TableCell>{trimmedItem["DINF-L1"]}</TableCell>
                  <TableCell>{trimmedItem["WTN-NUMBER"]}</TableCell>
                  <TableCell>{trimmedItem["DILTEL"] == "0000000000" ? 'N/A' : trimmedItem["DILTEL"]}</TableCell>
                  <TableCell>{trimmedItem["TOWN-NAME"]}</TableCell>
                  <TableCell>{trimmedItem["STREET-NAME"]} {trimmedItem["HOUSE-NUM"]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;
