// src/Disclaimer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Disclaimer = () => {
  const navigate = useNavigate();

  const handleEnter = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get('t');
  
   
  
    // Get current time in Puerto Rico time zone
    const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Puerto_Rico" }));
    const currentTime = currentDate.toTimeString().slice(0, 5);
  
    if (dateParam) {
      const [queryHours, queryMinutes] = dateParam.split(':').map(Number);
      const queryDate = new Date();
      queryDate.setHours(queryHours, queryMinutes, 0);
  
      const timeDifference = (currentDate - queryDate) / (1000 * 60);
      console.log(timeDifference)
      if (timeDifference <= 5) {
        
        navigate(`/table?t=${dateParam}`);
      } else {
        window.location.href = '/';
        alert('El siguiente enlace tiene una vida de 5 minutos. De no abrirle, quiere decir que su tiempo expiro y debe llamar nuevamente al 411.')
      }
    } else {
      // If no date parameter is present, redirect to home page
      window.location.href = '/';
      alert('El siguiente enlace tiene una vida de 5 minutos. De no abrirle, quiere decir que su tiempo expiro y debe llamar nuevamente al 411.')
    }
  };

  // Generate current date parameter
  const generateCurrentDateParam = () => {
    const currentDate = new Date().toISOString();
    return `http://localhost:3000/?date=${encodeURIComponent(currentDate)}`;
  };


  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Logo_de_Claro.svg" alt="Logo" style={{ marginBottom: '20px', width: 80, height: 80 }} /> {/* Replace with your logo */}
      <h1>Descargo de Responsabilidad</h1>
      <p>
        Este es un mensaje de descargo de responsabilidad. Al ingresar a este sitio, usted acepta nuestros términos y condiciones.
        Por favor, lea la información cuidadosamente.
      </p>
      <button onClick={handleEnter} style={{ padding: '10px 20px', fontSize: '16px' }}>
        Entrar
      </button>
    </div>
  );
};

export default Disclaimer;
