// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TableComponent from './TableComponent';
import Disclaimer from './Disclaimer';

function App() {
  return (
    <Router>
      <div style={{ padding: '20px' }}>
        <Routes>
          <Route path="/" element={<Disclaimer />} />
          <Route path="/table" element={<TableComponent />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
